import { useSelector } from 'react-redux';
import { useEffect, useMemo } from 'react';
import { useFormik } from 'formik';
import { Loader } from '@aws-amplify/ui-react';

import { PRIORITY, ProjectsNames, RoleNames } from 'hooks/permission/constants';

import useFormikErrors from 'hooks/formik/useFormikErrors';
import { useGetUser } from 'hooks/api/user/useGetUser';

import PermissionWrapper from 'components/PermissionWrapper';

import { Box, Button, Chip, Dialog, DialogContent, DialogTitle, FormControl, IconButton, InputLabel, MenuItem, OutlinedInput, Select, Stack, TextField, Typography } from '@mui/material';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import CancelIcon from '@mui/icons-material/Cancel';





import { RootState } from 'store';


type Props = {
    open: boolean;
    selectedUserId?: string;
    onClose: () => void;
    mutate: (values: PermissionsFormikValuesType) => void;
    deleteAllRightsHandler?: () => void;
};

export type PermissionsFormikValuesType = { email?: string; permission?: { role?: string; projects?: string[] }[] };

const DEFAULT_PERMISSION = { role: '', projects: [] };

const DetailsPermissionsModal = ({ open, onClose, selectedUserId, mutate, deleteAllRightsHandler }: Props) => {
    const { showErrorMessages } = useFormikErrors();

    const { data: selectedUser, isLoading } = useGetUser({ 
        id: selectedUserId, 
        onError(err) {
            showErrorMessages({
                errors: err?.data,
                showGlobalMessage: true,
                customMessage: 'Error fetching user'
            });
        }
    });

    useEffect(() => {
        formik.setFieldValue('email', selectedUser?.email);

        currentRoles.forEach((role, index) => {
            formik.setFieldValue(`permission[${index}]`, {
                role,
                projects: getProjectByRole(selectedUser.groups, role)
            });
        });

        return () => formik.resetForm();
    }, [selectedUser, isLoading]);

    const projectList = useSelector((state: RootState) => state.generalData.projectsList);
    const currentRoles = [...new Set(Object.values(selectedUser?.groups || {}))] as RoleNames[];

    function getProjectByRole(group: Record<RoleNames, ProjectsNames>, role?: string) {
        const projects = [];

        for (const [key, value] of Object.entries(group)) {
            if (value.toString() === role) projects.push(key);
        }

        return projects;
    }

    const formik = useFormik<PermissionsFormikValuesType>({
        initialValues: {
            email: '',
            permission: [{ ...DEFAULT_PERMISSION }]
        },
        onSubmit: (values) => {
            if (values.permission?.length) {
                mutate(values);
            } else {
                deleteAllRightsHandler && deleteAllRightsHandler();
            }
            onClose();
        }
    });

    const closeModal = () => {
        onClose();
    };

    const handleDeletePermission = (role: string) => {
        formik.setFieldValue(
            'permission',
            formik?.values?.permission?.filter((permission) => permission !== role)
        );
    };

    const handleAddPermission = () => {
        formik.setFieldValue('permission', formik.values.permission && [...formik.values.permission, { ...DEFAULT_PERMISSION }]);
    };

    const handleDeleteProject = ({ index, value }: { index: number; value: string }) => {
        formik.setFieldValue(
            `permission[${index}].projects`,
            formik.values?.permission?.[index]?.projects?.filter((item) => item !== value)
        );
    };

    const getFilteredProjectList = useMemo(() => {
        const flatMatOfCurrentProjects = formik.values.permission?.flatMap((item) => item?.projects);

        return projectList?.filter((value) => flatMatOfCurrentProjects?.indexOf(value.system_name.toString()) === -1);
    }, [formik.values.permission, projectList]);

    return (
        <Dialog open={open} data-locator="editModal">
            <DialogTitle align="center" style={{ paddingTop: '20px' }}>
                <Typography variant="h5">User editing</Typography>
            </DialogTitle>
            <DialogContent style={{ paddingTop: '20px', minWidth: 500 }}>
                {isLoading ? (
                    <Box display="flex" justifyContent="center">
                        <Loader width="40px" height="40px" />
                    </Box>
                ) : (
                    <form onSubmit={formik.handleSubmit}>
                        <Box gap="24px" display="flex" flexDirection="column" marginBottom="24px">
                            <TextField
                                fullWidth
                                data-locator="emailInput"
                                id="email"
                                name="email"
                                label="Email*"
                                disabled
                                value={formik.values.email}
                                onChange={formik.handleChange}
                                error={formik.touched.email && Boolean(formik.errors.email)}
                                helperText={formik.touched.email && formik.errors.email}
                            />
                            <Box display="flex" flexDirection="column" alignItems="start" gap={2}>
                                {!!formik.values?.permission &&
                                    Object.values(formik.values.permission)?.map((item, index) => {
                                        const formikRoles = Object.values(formik.values.permission || []);

                                        const isLast = index !== formikRoles.length - 1;

                                        return (
                                            <>
                                                <Box display="flex" flex="1" width="100%" key={`${item.projects}_${index}`}>
                                                    <Box width="100%" display="flex" flexDirection="column" gap="24px">
                                                        <FormControl>
                                                            <InputLabel id="select_project">Select project</InputLabel>
                                                            <Select
                                                                data-locator="projectDropdown"
                                                                labelId="select_project"
                                                                id="select_project"
                                                                multiple
                                                                value={item?.projects || []}
                                                                name={`permission[${index}].projects`}
                                                                onChange={formik.handleChange}
                                                                input={<OutlinedInput label="Multiple Select" />}
                                                                placeholder="Select project"
                                                                renderValue={(selected) => (
                                                                    <Stack gap={1} direction="row" flexWrap="wrap">
                                                                        {selected?.map((value) => (
                                                                            <Chip
                                                                                key={value}
                                                                                label={value}
                                                                                style={{ borderRadius: '25px' }}
                                                                                onDelete={() => handleDeleteProject({ index, value })}
                                                                                deleteIcon={
                                                                                    <CancelIcon
                                                                                        data-locator="deleteButton"
                                                                                        onMouseDown={(event) => event.stopPropagation()}
                                                                                    />
                                                                                }
                                                                            />
                                                                        ))}
                                                                    </Stack>
                                                                )}
                                                            >
                                                                {getFilteredProjectList?.map((project) => (
                                                                    <MenuItem
                                                                        data-locator="roleDropdown"
                                                                        key={project.id}
                                                                        value={project.system_name}
                                                                    >
                                                                        <Chip
                                                                            label={project.system_name}
                                                                            style={{ borderRadius: '25px' }}
                                                                        />
                                                                    </MenuItem>
                                                                ))}
                                                            </Select>
                                                        </FormControl>
                                                        <TextField
                                                            select
                                                            label="Select role"
                                                            onChange={formik.handleChange}
                                                            name={`permission[${index}].role`}
                                                            value={item?.role}
                                                        >
                                                            {PRIORITY.map((role) => (
                                                                <MenuItem key={role} value={role}>
                                                                    {role}
                                                                </MenuItem>
                                                            ))}
                                                        </TextField>
                                                    </Box>
                                                    <IconButton onClick={() => handleDeletePermission(item as string)}>
                                                        <DeleteForeverIcon />
                                                    </IconButton>
                                                </Box>
                                                {isLast && (
                                                    <hr
                                                        style={{
                                                            backgroundColor: '#000',
                                                            opacity: '0.5',
                                                            width: '100%'
                                                        }}
                                                    />
                                                )}
                                            </>
                                        );
                                    })}
                                <PermissionWrapper type="edit" name="users">
                                    <Button onClick={handleAddPermission} data-locator="addPermissionButton">
                                        <Typography fontWeight="700">+ ADD PERMISSION</Typography>
                                    </Button>
                                </PermissionWrapper>
                            </Box>
                        </Box>
                        <Box display="flex" width="100%" alignItems="stretch" gap="8px">
                            <Button data-locator="cancelButton" style={{ flex: '1' }} size="large" variant="outlined" onClick={closeModal}>
                                CANCEL
                            </Button>
                            <PermissionWrapper type="create" name="users">
                                <Button
                                    data-locator="saveButton"
                                    style={{ flex: '1' }}
                                    size="large"
                                    variant="contained"
                                    disabled={!formik.touched}
                                    type="submit"
                                >
                                    SAVE
                                </Button>
                            </PermissionWrapper>
                        </Box>
                    </form>
                )}
            </DialogContent>
        </Dialog>
    );
};

export default DetailsPermissionsModal;
